/**
 * Date / time picker
 * @requires https://github.com/flatpickr/flatpickr
 */
 import flatpckr from 'flatpickr';
 window.flatpckr = flatpckr;
 import rangePlugin from 'flatpickr/dist/plugins/rangePlugin.js';
 window.rangePlugin = rangePlugin;

const datePicker = (() => {
  let picker = document.querySelectorAll('.date-picker');
      
  if (picker.length === 0) return;
  
  for (let i = 0; i < picker.length; i++) {

    let defaults = {
      disableMobile: 'true'
    }
    // console.log('picker ', picker[i]);
    let userOptions;
    if(picker[i].dataset.datepickerOptions != undefined) userOptions = JSON.parse(picker[i].dataset.datepickerOptions);
    let linkedInput = picker[i].classList.contains('date-range') ? {"plugins": [new rangePlugin({ input: picker[i].dataset.linkedInput })]} : '{}';
    let options = {...defaults, ...linkedInput, ...userOptions}

    flatpickr(picker[i], options);
  }
})();

export default datePicker;
